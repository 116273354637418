.ant-btn {
    border-radius: 4px;
}
.ant-modal-header {
    .ant-modal-title {
        font-size: 18px;
        font-weight: 600;
        color: #171A1D;
        line-height: 25px;
    }
}
.ant-pagination {
    .ant-pagination-prev {
        margin-right: 4px;
        .ant-pagination-item-link {
            border-radius: 4px;
        }
    }
    .ant-pagination-next {
        margin-left: 4px;
        .ant-pagination-item-link {
            border-radius: 4px;
        }
    }
    .ant-pagination-item {
        border-radius: 2px;
        margin: 0 4px;
    }
    .ant-pagination-item-active  {
        background: #5CB8B2;
        a {
            color: #ffffff;
        }
    }
}


