$defaultFontSize: 16px;
$largeFontSize: 18px;
$smallFontSize: 14px;
body.body-interviewer {
    font-size: $defaultFontSize;
    .react-form--inner--large {
        .react-form__field-label {
            font-size: $defaultFontSize;
        }
        .form-label-field-value {
            font-size: $defaultFontSize;
        }
        .ant-radio-wrapper {
            font-size: $defaultFontSize;
            color: rgba(0, 0, 0, 0.65);
        }
        .ant-modal-footer {
            .ant-btn {
                height: 35px;
                font-size: 18px;
                padding:2px 15px;
            }
        }
    }
    .candidate_detail_container {
        font-size: $defaultFontSize;
        .candidate_profile {
            .candidate_profile_info {
                .candidate_profile_top {
                    font-size: $largeFontSize;
                }
            }
        }
        .resume_container {
            .resume {
                font-size: $defaultFontSize;
            }
        }
        .ant-tabs-tab {
            font-size: $defaultFontSize;
        }
        .candidate_feedback_container {
            .title {
                font-size: $largeFontSize;
            }
        }
        
        .state {
            font-size: $defaultFontSize;
        }
        .interview_progress_steps {
            .interviewProgressRemark {
                font-size: $defaultFontSize;
                line-height: 21px;
            }
        }
        .ant-empty {
            font-size: $defaultFontSize;
        }
    }
    .select-interviewer-actions {
        padding-left: 0;
    }
}