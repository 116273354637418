.page-content {
  padding: 20px 20px;
  height: 100%;
}

.page-header {
  padding: 0 20px 0 150px;
  background: #FFFFFF;

  :global(.ant-menu-item-selected), :global(.ant-menu-item-active) {
    color: #1890ff;
    font-weight: bold;
  }

  :global(.ant-btn-link) {
    color: #333;
  }
}

.page-footer {
  text-align: center;
}

.page-layout {
  min-height: 100vh;
  &:global(.position) .page-content{
    padding: 0;
  }
}

.page-logo {
  width: 40px;
  height: 40px;
}

.nav-msg {
  font-size: 18px;
}

.layout-row {
  height: 100vh;
  width: 100vw;
  position: relative;
}

.layout-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.layout-inner {
  padding: 50px;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 2px;
  position: absolute;
  top: 40%;
  left: 50%;
  width: 500px;
  transform: translate(-50%, -50%);
}
