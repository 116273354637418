body {
  font-family: PingFangSC-Regular, PingFang SC;
  color: rgba(0, 0, 0, 0.65);
  min-width: 800px;
}
.ant-layout {
  background: #F7F7F7;
}

.container-border {
  background: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #EAECED;
  padding: 14px 12px;

  &-title {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
  }
}

.ant-avatar.ant-avatar-square {
  width: 32px;
  height: 32px;
  background: #EFEFEF;
  box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.1);
  border-radius: 4px;
  border: 1px solid #FFFFFF;
}
